
$breath-size: 10vw;

.breath-emitter {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  background-color: #000;
  margin: -100px;
  top: 50%;
  left: 50%;
  z-index: 1;
  //box-shadow: 0 0 10vw 8vw rgba(0,0,0,1), 0 0 10vw 20vw rgba(255,255,255,0.6);
  /*
  box-shadow:
          0 0 60px 30px #fff,
          0 0 100px 60px #f0f,
          0 0 140px 90px #0ff;

  */
  // try https://codepen.io/lonekorean/pen/ExVXjv ?
  /*box-shadow: 0px 0px 143px 64px rgba(45,2,196,0.8), 0px 0px 43px 30px rgba(45,255,196,0.8);*/
}

.breathing-guide {
  position: absolute;
  top: -3vw;
  left: 0;
  transform: translate3d(-50%, 0, 0);
  z-index: 10;
}
