.focusing-screen {
  max-width: 680px;
  text-align: center;
  align-self: flex-start;
  margin-top: 240px;
  h2 {
    font-size: 6rem;
    line-height: 1;
    text-align: center;
    margin: 0;
    color: var(--pink);
    font-family: var(--pixel-font);
    text-shadow: 3px 3px 0px var(--dark-blue);
  }
}


.focusing-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0 2rem;
  text-align: left;
}

.focusing-item {
  margin: 1rem 0;
  cursor: pointer;
  color: var(--silver);

  input {
    visibility: hidden;
  }

  .__checkbox {
    color: var(--grey);
    margin-right: 1rem;
    font-size: 2rem;
    font-family: var(--pixel-font);
  }

  .__name {
    font-size: 2rem;
    font-family: var(--pixel-font);
  }
  i {
    font-style: normal;
    display: block;
    position: relative;
    left: 2rem;
    margin-top: .25rem;
    white-space: pre-wrap;
  }

  &.--active {
    color: white;

    .__checkbox {
      color: var(--pink);
    }
  }
}

.focusing-preparation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(100,100,100,0.9);
  position: relative;
  z-index: 20;
  //height: 320px;
  padding: 1rem;
  font-size: 1.5rem;
}

.focusing-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.focusing-text {
  font-size: 1.2rem;
  line-height: 1.5;
}

.focusing-speech {
  display: block;
  font-family: var(--pixel-font);
  color: var(--primary);
}

.focusing-goals {
  font-family: var(--pixel-font);
  text-align: left;
  margin: 1rem 0;

  .__title {
    color: var(--pink);
    font-size: 2rem;
  }
  .__goal {
    margin: .5rem 0;

    b {
      font-weight: normal;
      color: var(--primary);
    }
    i {
      font-style: normal;
      color: var(--pink);
    }
  }
}


// todo: split screens!
//=======================
.focusing-heart {
  position: fixed;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
}
.focusing-cancel {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    background-color: var(--grey);
    color: white;
    margin-left: 10px;
    font-size: 1.2rem;
    padding: .5rem 1rem;
    border: none;
    cursor: pointer;
    font-family: var(--pixel-font);
  }
}
.focusing-failed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: var(--grey);
  padding: 1rem;
  z-index: 5000;
  width: 600px;

  .__blinked {
    color: var(--pink);
    font-size: 4rem;
    margin: 2rem 0;
    font-family: var(--pixel-font);
  }

  .__buttons {
    display: flex;
    justify-content: space-between;
  }
}