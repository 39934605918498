@font-face {
    font-family: 'Halki pixel';
    src: url('./font/editundobrk-rwav-webfont.woff2') format('woff2'),
    url('./font/editundobrk-rwav-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
:root {
    --primary: #00DEE9;
    --highlight: #91FBFF;
    --medium-blue: #009aa3;
    --medium-blue-hover: #00bdc6;
    --dark-blue: #006c73;
    --grey: #565656;
    --silver: #C0C0C0;
    --purple: #541E80;
    --pink: #ffa5fb;
    --pixel-font: 'Halki pixel', sans-serif;
    --background: radial-gradient(#393939, #000000);
    --mobile: 768px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    overflow-x: hidden;
    font-size: 16px;
}

.app {
    background: var(--background);
    color: white;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    &.--calm-mode {
        --primary: #dffeff;
        --highlight: #ffffff;
        --medium-blue: #a5b9ba;
        --medium-blue-hover: #bbd0d1;
        --dark-blue: #5e777a;
        --pink: #ffe3ff;
        --background: radial-gradient(#555555, #222222);
    }

    &.--overflow-hidden {
        overflow: hidden;
    }

    @media screen and (max-width: 600px) {
        min-height: calc(100vh - 4rem);
        padding-bottom: 4rem;
    }
}

img {
    image-rendering: pixelated;
    pointer-events: none;
}

.appver {
    position: fixed;
    left: 10px;
    bottom: 10px;
    font-size: 14px;
    color: #ffffff;
    opacity: .5;
}