.webcam {
  position: absolute;
  top: 0;
  left: 0;
  width: 640px;
  height: 480px;
  z-index: 100;
  transform: scaleX(-1);
  visibility: hidden;
  pointer-events: none;
}
.blink-detector {
  position: relative;
  z-index: 10;
}