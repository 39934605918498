.title-screen {
  //outline: 1px solid red;
  h1, h2 {
    font-family: var(--pixel-font);
    margin: 0;
  }

  h1 {
    font-size: 8rem;
    line-height: .8;
    text-align: center;
    margin: 0;
    color: var(--pink);
    text-shadow: 3px 3px 0px var(--dark-blue);

    @media screen and (max-width: 600px) {
      margin-top: 1rem;
    }
  }

  text-align: center;
}


.title-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex: 0 0 58%;
  text-align: start;
}

.title-center {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.5rem;

  @media screen and (max-width: 600px) {
    display: block;
  }

  > div {
    //outline: 1px solid blue;
  }
}

.title-text {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0 0 1.4rem;
}

.title-buttons {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  .button {
    width: 100%;
  }
}

.title-halki {
  transform: scale(1.1);
}